var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useState } from "react";
import Headroom from "react-headroom";
import { SearchInput } from "../../../../../pkgs/src/components/SearchInput";
import { UserSettingsWrapped } from "../../../../../pkgs/src/components/UserSettingsWrapped";
import styles from "./styles.module.scss";
var SearchHeader = function (props) {
    var _a = useState(false), settingsVisible = _a[0], setSettingsVisible = _a[1];
    var showSettings = function () {
        setSettingsVisible(true);
    };
    var hideSettings = function () {
        setSettingsVisible(false);
    };
    return (_jsx(Headroom, __assign({ upTolerance: 1, downTolerance: 0 }, { children: _jsxs("div", __assign({ className: "padding-below-gdpr-popup" }, { children: [_jsx(UserSettingsWrapped, { isOpen: settingsVisible, close: hideSettings }, void 0),
                _jsxs("header", __assign({ className: styles.header }, { children: [_jsx("a", __assign({ className: styles.brand, href: "//tahiti.ai/", target: "_blank", rel: "noopener noreferrer" }, { children: _jsxs("div", __assign({ className: styles.brandItems }, { children: [_jsx("div", __assign({ className: styles.rotateLogo }, { children: _jsx("span", __assign({ style: { fontSize: "26px" }, role: "img", "aria-label": "Tahiti" }, { children: "\uD83C\uDF34" }), void 0) }), void 0),
                                    _jsx("span", __assign({ className: styles.brandName }, { children: "Tahiti" }), void 0)] }), void 0) }), void 0),
                        _jsx(SearchInput, { className: styles.search, search: props.search, setSearch: props.setSearch }, void 0),
                        _jsx(Button, __assign({ onClick: showSettings }, { children: _jsx(SettingsIcon, { className: styles.settingsIcon }, void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export { SearchHeader };
