var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { tagsAll } from "./hardcodedTags";
import { Hints } from "./Hints";
var useStyles = makeStyles(function (theme) { return ({
    popper: {
        zIndex: 1,
        maxWidth: "50%",
    },
}); });
var findHints = function (partialWord) {
    if (partialWord.length === 0) {
        // return categories;
        return [];
    }
    var partialWordLower = partialWord.toLowerCase();
    var counter = 0;
    var hintArray = tagsAll.filter(function (candidate) {
        if (counter < 20 && candidate.startsWith(partialWordLower)) {
            counter++;
            return true;
        }
        else {
            return false;
        }
    });
    if (hintArray.length === 20) {
        hintArray[20] = "...";
    }
    return hintArray;
};
var getLastWord = function (text) {
    if (text.endsWith(" ")) {
        return "";
    }
    var newList = text.split(" ").filter(function (candidate) { return candidate.length > 0; });
    var lastWord = newList.length === 0 ? "" : newList[newList.length - 1];
    return lastWord;
};
var SearchInput = function (props) {
    var classes = useStyles();
    var _a = useState([]), hints = _a[0], setHints = _a[1];
    var inputRef = useRef(null);
    useEffect(function () {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);
    var freshenSearch = function (newSearch) {
        var lastWord = getLastWord(newSearch);
        var hints = findHints(lastWord);
        setHints(hints);
        props.setSearch(newSearch);
    };
    var handleSearchChange = function (event) {
        freshenSearch(event.target.value);
    };
    var addTagFromHint = function (hint) {
        var lastWord = getLastWord(props.search);
        if (lastWord) {
            var newSearch = props.search.substr(0, props.search.lastIndexOf(lastWord)) +
                hint +
                " ";
            freshenSearch(newSearch);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("input", { className: props.className, ref: inputRef, type: "text", onChange: handleSearchChange, value: props.search, placeholder: "hint: search for appliance or chair" }, void 0),
            _jsx(Popper, __assign({ className: classes.popper, open: hints.length > 0, anchorEl: inputRef.current, placement: "bottom-start", transition: true }, { children: function (_a) {
                    var TransitionProps = _a.TransitionProps;
                    return (_jsx(Fade, __assign({}, TransitionProps, { timeout: 350 }, { children: _jsx(Paper, { children: _jsx(Hints, { hints: hints, removable: false, onClick: addTagFromHint }, void 0) }, void 0) }), void 0));
                } }), void 0)] }, void 0));
};
export { SearchInput };
