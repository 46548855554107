var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useFetcher } from "rest-hooks";
import { AssetImage } from "../../../../../pkgs/src/components/AssetImage";
import { DetailsResource } from "../../../core/searchSdk/DetailsResource";
import { DetailsExtras } from "../DetailsExtras";
import { DetailsSummary } from "../DetailsSummary";
import styles from "./styles.module.scss";
var useStyles = makeStyles(function (theme) { return ({
    gridContainer: {},
}); });
var DetailsArea = function (props) {
    var _a = useState(null), product = _a[0], setProduct = _a[1];
    var _b = useState(false), received = _b[0], setReceived = _b[1];
    var getDetails = useFetcher(DetailsResource.listShape());
    useEffect(function () {
        getDetails({ handle: props.handle })
            .catch(function (reason) { return console.log(reason); })
            .then(function (data) {
            setProduct(data);
            setReceived(true);
        }, function () { });
    }, [getDetails, props.handle]);
    var classes = useStyles();
    if (product === null || product === undefined) {
        if (received) {
            return _jsx("span", { children: "Not found" }, void 0);
        }
        else {
            return (_jsx("span", {}, void 0));
        }
    }
    return (_jsxs("div", __assign({ className: classnames(props.className, styles.outer), style: props.style }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1, className: classes.gridContainer }, { children: [_jsx(Grid, __assign({ item: true, lg: 5, md: 5, sm: 12 }, { children: _jsx(AssetImage, { product: product }, void 0) }), void 0),
                    _jsx(Grid, __assign({ item: true, lg: 7, md: 7, sm: 12 }, { children: _jsx(DetailsSummary, { product: product }, void 0) }), void 0)] }), void 0),
            _jsx("div", __assign({ className: styles.tabArea }, { children: _jsx(DetailsExtras, {}, void 0) }), void 0)] }), void 0));
};
export { DetailsArea };
