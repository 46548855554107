var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classnames from "classnames";
import React from "react";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import VisibilitySensor from "react-visibility-sensor";
import styles from "./styles.module.scss";
var AssetThumbnail = function (props) {
    var img = props.product.images[props.product.heroImage];
    return (_jsx("div", __assign({ className: classnames(props.className, styles.outer), style: props.style }, { children: img && (_jsx(_Fragment, { children: _jsx(Link, __assign({ className: styles.clickable, to: props.linkTo }, { children: _jsx(VisibilitySensor, { children: _jsx(Img, { src: img.thumb.replace("+", "%2B"), loader: _jsx("div", __assign({ className: styles.clickableLoading }, { children: _jsx("div", __assign({ className: styles.clickableLoading2 }, { children: _jsx(ScaleLoader, { color: "#ddd" }, void 0) }), void 0) }), void 0), unloader: _jsx("div", __assign({ className: styles.clickableLoading }, { children: _jsx("div", __assign({ className: styles.clickableLoading2 }, { children: _jsx("span", { children: "No image" }, void 0) }), void 0) }), void 0) }, void 0) }, void 0) }), void 0) }, void 0)) }), void 0));
};
export { AssetThumbnail };
