var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
import React from "react";
import styles from "./styles.module.scss";
import { Tag } from "./Tag";
var Hints = function (props) {
    if (props.hints.length === 0) {
        return (_jsx("div", { className: classnames(props.className, styles.outer), style: props.style }, void 0));
    }
    return (_jsx("div", __assign({ className: classnames(props.className, styles.outer), style: props.style }, { children: props.hints.map(function (tag, index) {
            if (tag === "qqz") {
                return null;
            }
            return (_jsx(Tag, { onClick: props.onClick, removable: props.removable, onRemove: props.onRemove, text: tag }, index));
        }) }), void 0));
};
export { Hints };
