var constants = {
    searchRoot: "",
};
var getConstants = function () {
    return constants;
};
var setConstants = function (arg) {
    Object.keys(arg).forEach(function (key) {
        constants[key] = arg[key];
    });
};
export { getConstants, setConstants, };
