var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Resource } from "rest-hooks";
import { searchRoot } from "../../../constants";
function fixupData(data) {
    var result = __assign(__assign({}, data), { hits: data.hits.map(function (product) {
            return __assign({}, product);
        }) });
    return result;
}
var unfixupData = function (data) {
    var result = __assign({}, data);
    return result;
};
var SearchResource = /** @class */ (function (_super) {
    __extends(SearchResource, _super);
    function SearchResource() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hits = [];
        _this.offset = 0;
        _this.limit = 0;
        _this.query = "";
        return _this;
    }
    Object.defineProperty(SearchResource, "key", {
        get: function () {
            return "SearchResource";
        },
        enumerable: false,
        configurable: true
    });
    SearchResource.listUrl = function (searchParams) {
        if (searchParams && Object.keys(searchParams).length) {
            var query = searchParams.query, limit = searchParams.limit, offset = searchParams.offset, realSearchParams = __rest(searchParams, ["query", "limit", "offset"]);
            var params = new URLSearchParams(realSearchParams);
            // sort is essential for consistent url strings
            params.sort();
            var url = searchRoot + "/v1.0/api/library/search?query=" + query + "&limit=" + limit + "&offset=" + offset + "&" + params.toString();
            return url;
        }
        throw new Error("Invalid search arguments");
    };
    SearchResource.listShape = function () {
        return __assign(__assign({}, _super.listShape.call(this)), { schema: {
                hits: [],
                offset: 0,
                limit: 0,
                query: "",
            } });
    };
    SearchResource.fetch = function (method, url, body) {
        if (method === void 0) { method = "get"; }
        return __awaiter(this, void 0, void 0, function () {
            var jsonResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // we'll need to do the inverse operation when sending data back to the server
                        if (body) {
                            body = unfixupData(body);
                        }
                        return [4 /*yield*/, _super.fetch.call(this, method, url, body)];
                    case 1:
                        jsonResponse = (_a.sent());
                        // do the conversion!
                        return [2 /*return*/, fixupData(jsonResponse)];
                }
            });
        });
    };
    SearchResource.prototype.pk = function () {
        return this.query + "&" + this.limit + "&" + this.offset;
    };
    return SearchResource;
}(Resource));
export { SearchResource };
