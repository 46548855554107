var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight, faArrowUp, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
library.add(faArrowUp, faArrowLeft, faArrowRight);
var DetailsHeader = function (props) {
    var handleIndex = props.searchResults.hits.findIndex(function (candidate) { return candidate.handle === props.handle; });
    var nextIndex = handleIndex < 0
        ? -1
        : handleIndex + 1 < props.searchResults.hits.length
            ? handleIndex + 1
            : 0;
    var prevIndex = handleIndex < 0
        ? -1
        : handleIndex - 1 >= 0
            ? handleIndex - 1
            : props.searchResults.hits.length - 1;
    return (_jsx(Headroom, __assign({ upTolerance: 1, downTolerance: 0 }, { children: _jsx("div", __assign({ className: "padding-below-gdpr-popup" }, { children: _jsxs("header", __assign({ className: classnames(styles.header, "padding-below-gdpr-popup") }, { children: [_jsxs(Link, __assign({ className: styles.up, to: "/" }, { children: [_jsx(FontAwesomeIcon, { icon: ["fas", "arrow-up"], size: "2x" }, void 0), "\u00A0"] }), void 0),
                    prevIndex >= 0 && (_jsxs(Link, __assign({ className: styles.prev, to: "/id/" + props.searchResults.hits[prevIndex].handle }, { children: [_jsx(FontAwesomeIcon, { icon: ["fas", "arrow-left"], size: "2x" }, void 0), "\u00A0"] }), void 0)),
                    nextIndex >= 0 && (_jsxs(Link, __assign({ className: styles.next, to: "/id/" + props.searchResults.hits[nextIndex].handle }, { children: [_jsx(FontAwesomeIcon, { icon: ["fas", "arrow-right"], size: "2x" }, void 0), "\u00A0"] }), void 0))] }), void 0) }), void 0) }), void 0));
};
export { DetailsHeader };
