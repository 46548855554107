var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import React from "react";
import styles from "./styles.module.scss";
var DetailsExtras = function (props) {
    return (_jsxs("div", __assign({ className: classnames(props.className, styles.outer), style: props.style }, { children: [_jsx("div", __assign({ className: styles.pdTabButtons }, { children: _jsx("div", __assign({ className: "nav", role: "tablist" }, { children: _jsx("a", __assign({ className: "active", href: "#tab1", "data-toggle": "tab", role: "tab", "aria-selected": "true", "aria-controls": "tab1" }, { children: "Details" }), void 0) }), void 0) }), void 0),
            _jsx("div", __assign({ className: "tab-content" }, { children: _jsx("div", __assign({ className: "tab-pane active show fade", id: "tab1", role: "tabpanel" }, { children: _jsx("div", __assign({ className: styles.pdTabItems }, { children: _jsxs("div", __assign({ className: styles.pdDetailsList }, { children: [_jsx("p", { children: "Use the Pipeline API to integrate your own custom asset details here." }, void 0),
                                _jsx("span", { children: "Projects this asset was used in" }, void 0),
                                _jsx("span", { children: "Poly count" }, void 0),
                                _jsx("span", { children: "Rigging and texturing status" }, void 0),
                                _jsx("span", { children: "Animation cycle availablity" }, void 0),
                                _jsx("span", { children: "etc." }, void 0)] }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0));
};
export { DetailsExtras };
