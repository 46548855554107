var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var Error404 = function () {
    return (_jsxs("div", __assign({ className: "d-flex justify-content-center align-items-center", style: { height: "250px" } }, { children: [_jsx("h1", __assign({ className: "mr-3 pr-3 align-top border-right inline-block align-content-center" }, { children: "404" }), void 0),
            _jsx("div", __assign({ className: "inline-block align-middle" }, { children: _jsx("h2", __assign({ className: "font-weight-normal lead", id: "desc" }, { children: "The page you requested was not found" }), void 0) }), void 0)] }), void 0));
};
export { Error404 };
