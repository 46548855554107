import * as io from "socket.io-client";
import { getConstants } from "../../pkgConstants";
import { hmacsha1 } from "./hmacsha1";
var sApiKey = "";
var sUsername = "";
var sSocket = null;
var connect = function (apiKey, username) {
    if (apiKey === sApiKey && username === sUsername) {
        return;
    }
    if (sSocket) {
        sSocket.close();
        sSocket = null;
    }
    sApiKey = apiKey;
    sUsername = username;
    sSocket = io.connect(getConstants().searchRoot);
    sSocket.on("connect", function () {
        if (sSocket) {
            var plugin = "login";
            var handle = "";
            var hash = hmacsha1(sApiKey, sUsername, plugin, handle);
            sSocket.emit("set-userId", {
                username: sUsername,
                plugin: plugin,
                handle: handle,
                hash: hash,
            });
        }
    });
    sSocket.on("reconnect", function () {
        if (sSocket) {
            var plugin = "login";
            var handle = "";
            var hash = hmacsha1(sApiKey, sUsername, plugin, handle);
            sSocket.emit("set-userId", {
                username: sUsername,
                plugin: plugin,
                handle: handle,
                hash: hash,
            });
        }
    });
    // sSocket.on("plugin", (data: any) => {
    //     console.log("received plugin");
    //     console.log(data);
    // });
};
var apiCmd = function (plugin, handle) {
    if (sSocket) {
        var hash = hmacsha1(sApiKey, sUsername, plugin, handle);
        var packet = {
            username: sUsername,
            plugin: plugin,
            handle: handle,
            hash: hash,
        };
        sSocket.emit("do-plugin", packet);
    }
};
export { apiCmd, connect };
